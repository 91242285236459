const hamburger = () => {
    const body = document.querySelector("[data-body]");
    const header = document.querySelector("[data-header]");
    const ham = header.querySelector("[data-hamburger]");
    const menu = header.querySelector("[data-nav]");
    const menuDropdown = header.querySelector("[data-nav-dropdown]");

    ham.addEventListener("change", (e) => {
        if (e.target.checked === true) {
            ham.classList.add('is-active')
            menu.classList.add('is-open')
            header.classList.add('is-open')
            body.classList.add('is-fixed')
        }
        if (e.target.checked === false) {
            ham.classList.remove('is-active')
            menu.classList.remove('is-open')
            header.classList.remove('is-open')
            body.classList.remove('is-fixed')
        }
    });

    if(menuDropdown) {
        menuDropdown.addEventListener('click', () => {
            let e = header.querySelector('.has-submenu')
            e.classList.toggle('is-open');
        })
    }
};
