$(function () {
    setHeaderScroll();
    hamburger();

    // ----- If header is fixed add paddig-top to body -----
    const header = document.querySelector('[data-header]')
    const body = document.querySelector('[data-body]')
    body.style.paddingTop = header.offsetHeight+'px'
});

function scrolling() {
    // textFun();
}

function resizing() {
	// textFun();
}

$(document).on('scroll', scrolling);
$(window).on('resize', resizing);
